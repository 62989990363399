import React, { useState, useEffect } from "react";
import { Alert, Modal, Button, Row, Col, Container, Form } from 'react-bootstrap';
import axios from 'axios';
import { FaSearch, FaArrowLeft } from "react-icons/fa";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const MasterProposalDetail = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [isPdf, setIsPdf] = useState(false);

    const navigate = useNavigate();
    const { id_proposal, usernya } = useParams();
    const username = usernya;

    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');

    const handleShowModal = (contentUrl, title, isPdfFile = false) => {
        setModalContent(contentUrl);
        setModalTitle(title);
        setIsPdf(isPdfFile);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);


    const handleClickBack = () => {
        navigate(`/master-proposal`);
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/FormReview`, {
                    params: { username }
                });
                setData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('An error occurred while fetching data');
                setLoading(false);
            }
        };

        fetchData();
    }, [username]);

    const isFormValid = () => {
        return status && reason; 
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isFormValid()) {
            return;
        }

        Swal.fire({
            title: `Apakah anda telah melakukan review dokumen dan akan memberikan response?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_SERVER}/proposalUpdate`, { id_proposal, status, reason })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil!',
                                text: `${response.data.message}`,
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Gagal!',
                                text: `${response.data.message}`,
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                            });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Gagal!',
                            text: 'Terjadi kesalahan, coba lagi.',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                        });
                    });
            }
        });
    };



    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
            <span onClick={() => handleClickBack()} className="mb-3 bg-dark-subtle mb-4 p-2 rounded"><FaArrowLeft /> <b>Back</b></span>
            <Alert key="info" variant="info" className="mt-4">
                Periksa dokumen pengajuan berikut dan berikan respon pada form di bawah
            </Alert>

            <div style={{ fontSize: '12px', height: "70vh", overflowY: "auto" }}>               
                <p className="mb-2">
                    <b>Informasi Perusahaan</b>
                </p>

                <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: <span key="1">{data.nama_perusahaan}</span></Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Bentuk Usaha <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.bentuk_perusahaan}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Kategory Mitra <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.kategori_mitra}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Alamat Kantor (Sesuai NPWP) <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.alamat_kantor}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Alamat Korespondensi <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.alamat_korespondensi}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nomor Telepon Kantor <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.nomor_telepon}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nomor Faksimili Kantor</Col>
                        <Col xs={8} className="px-0">: {data.nomor_fax}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Email Kantor <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.email_kantor}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Logo Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: &nbsp;
                            <span onClick={() => handleShowModal(`logo/${data.logo_perusahaan}`, 'Logo Perusahaan')} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                </Container>

                <p className="mb-2">
                    <b>Keanggotaan Perusahaan</b>
                </p>
                <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Anggota Komisaris Utama <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={8} className="px-0">: {data.komisaris_utama}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Anggota Komisaris 1 <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0">: {data.komisaris_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Nama Anggota Komisaris 2</Col>
                        <Col xs={8} className="px-0">: {data.komisaris_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0">: {data.direktur_utama}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Direktur 1</Col>
                        <Col xs={8} className="px-0">: {data.direktur_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Nama Direktur 2</Col>
                        <Col xs={8} className="px-0">: {data.direktur_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Pemegang Saham 1 <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0">: {data.pemegang_saham_1}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Pemegang Saham 2</Col>
                        <Col xs={8} className="px-0">: {data.pemegang_saham_2}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0">Pemegang Saham 3</Col>
                        <Col xs={8} className="px-0">: {data.pemegang_saham_3}</Col>
                    </Row>
                </Container>

                <p className="mb-2">
                    <b>Informasi Direktur Utama</b>
                </p>
                <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Email Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0"> : {data.email_dirut}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> No. Hp Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={8} className="px-0"> : {data.nomor_telepon_dirut}</Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> KTP Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={5} className="px-0"> : {data.ktp_dirut}</Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`data_dirut/${data.file_ktp_dirut}`, 'KTP Direktur Utama')} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> NPWP Direktur Utama <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={5} className="px-0"> : {data.npwp_dirut} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`data_dirut/${data.file_npwp_dirut}`, 'NPWP Direktur Utama')} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                </Container>

                <p className="mb-2">
                    <b>Informasi File Perusahaan</b>
                </p>
                <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> NPWP Perusahaan <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.npwp_perusahaan} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_npwp_perusahaan}`, 'NPWP PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> SPPKP <span className="text-danger"><b>*</b> </span> </Col>
                        <Col xs={5} className="px-0"> : {data.sppkp} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_sppkp}`, 'SPPKP PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> NIB <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0">: {data.nib} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_nib}`, 'NIB PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> SIUP <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.siup} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_siup}`, 'SIUP PERUSAHAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Kepesertaan BPJS Ketenagakerjaan <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.bpjs_ketenagakerjaan}</Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_bpjs_ketenagakerjaan}`, 'KEPESERTAAN BPJS KETENAGAKERJAAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Akta Pendirian Terbaru <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.akta_pendirian} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_akta_pendirian}`, 'AKTA PENDIRIAN TERBARU', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Pengesahan Akta Pendirian <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.pengesahan_akta_pendirian} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_pengesahan_akta_pendirian}`, 'PENGESAHAN AKTA PENDIRIAN', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> AD/ART Terbaru <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.ad_art} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_ad_art}`, 'AD/ART TERBARU', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Pengesahan AD/ART <span className="text-danger"><b>*</b> </span></Col>
                        <Col xs={5} className="px-0"> : {data.pengesahan_ad_art} </Col>
                        <Col xs={3} className="px-2 text-end">
                            <span onClick={() => handleShowModal(`file_perusahaan/${data.file_pengesahan_ad_art}`, 'PENGESAHAAN AD/ART', true)} className="fw-bold" style={{ cursor: 'pointer' }}>
                                <FaSearch /> Preview
                            </span>
                        </Col>
                    </Row>
                </Container>

                <p className="mb-2">
                    <b>Informasi Data Laporan Perusahaan 3 Tahun Terakhir</b>
                </p>
                <Container style={{ borderBottom: "1px solid #CCC" }} className="pb-2 mb-2">
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Laporan Keuangan Holding </Col>
                        <Col xs={8} className="px-0"> Laporan Rugi Laba </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> 2023 : &nbsp;
                            {data.laporan_keuangan_holding_2023 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_keuangan_holding_2023}`,
                                            'LAPORAN KEUANGAN HOLDING 2023',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File <span className="text-danger"><b>*</b> </span>
                                </span>
                            ) : (<>-</>)}
                        </Col>
                        <Col xs={8} className="px-0"> 2023 : &nbsp;
                            {data.laporan_laba_rugi_2023 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_laba_rugi_2023}`,
                                            'LAPORAN LABA RUGI 2023',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File <span className="text-danger"><b>*</b> </span>
                                </span>
                            ) : (<>-</>)}
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> 2022 : &nbsp;
                            {data.laporan_keuangan_holding_2022 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_keuangan_holding_2022}`,
                                            'LAPORAN KEUANGAN HOLDING 2022',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                        <Col xs={8} className="px-0"> 2022 : &nbsp;
                            {data.laporan_laba_rugi_2022 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_laba_rugi_2022}`,
                                            'LAPORAN LABA RUGI 2022',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> 2021 : &nbsp;
                            {data.laporan_keuangan_holding_2021 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_keuangan_holding_2021}`,
                                            'LAPORAN KEUANGAN HOLDING 2021',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                        <Col xs={8} className="px-0"> 2021 : &nbsp;
                            {data.laporan_laba_rugi_2021 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_laba_rugi_2021}`,
                                            'LAPORAN LABA RUGI 2021',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                    </Row>

                    <Row className="pb-0 mb-0 mt-2">
                        <Col xs={4} className="px-0"> Laporan SPT </Col>
                        <Col xs={8} className="px-0"> Laporan Neraca </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> 2023 : &nbsp;
                            {data.laporan_spt_2023 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_spt_2023}`,
                                            'LAPORAN SPT 2023',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File <span className="text-danger"><b>*</b> </span>
                                </span>
                            ) : (<>-</>)}
                        </Col>
                        <Col xs={8} className="px-0"> 2023 : &nbsp;
                            {data.laporan_neraca_2023 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_neraca_2023}`,
                                            'LAPORAN NERACA 2023',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File <span className="text-danger"><b>*</b> </span>
                                </span>
                            ) : (<>-</>)}
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> 2022 : &nbsp;
                            {data.laporan_spt_2022 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_spt_2022}`,
                                            'LAPORAN SPT 2022',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                        <Col xs={8} className="px-0"> 2022 : &nbsp;
                            {data.laporan_neraca_2022 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_neraca_2022}`,
                                            'LAPORAN NERACA 2022',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                    </Row>
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> 2021 : &nbsp;
                            {data.laporan_spt_2021 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_spt_2021}`,
                                            'LAPORAN SPT 2021',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                        <Col xs={8} className="px-0"> 2021 : &nbsp;
                            {data.laporan_neraca_2021 ? (
                                <span
                                    onClick={() =>
                                        handleShowModal(
                                            `laporan_keuangan/${data.laporan_neraca_2021}`,
                                            'LAPORAN NERACA 2021',
                                            true
                                        )
                                    }
                                    className="fw-bold"
                                    style={{ cursor: 'pointer' }}
                                >
                                    File
                                </span>
                            ) : (<>-</>)}
                        </Col>
                    </Row>
                </Container>

                <p className="mb-2">
                    <b>Informasi Dokumen Pengajuan</b>
                </p>
                <Container
                    style={{ borderBottom: "1px solid #CCC" }}
                    className="pb-4 mb-4"
                >
                    <Row className="pb-0 mb-0">
                        <Col xs={4} className="px-0"> Dokumen Pengajuan <span className="text-danger"><b>*</b></span></Col>
                        <Col xs={8} className="px-0"> : &nbsp;
                            <a href={`${process.env.REACT_APP_SERVER}/uploads/dokumen/${data.file_proposal}`} download>
                                {data.file_proposal} - Download file zip
                            </a>
                        </Col>
                    </Row>

                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-0 mt-4" style={{border: '0px solid red'}}>
                            <Col xs={4} className="p-2 text-center">
                                <Form.Group controlId="formStatus">
                                    <Form.Control
                                        as="select"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Status</option>
                                        <option value="APPROVED">APPROVED</option>
                                        <option value="REJECTED">REJECTED</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="p-2 text-end">
                                <Form.Group controlId="formReason" className="mt-0">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter reason"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4} className="p-2 text-start">
                                <Button
                                    className="bg-warning"
                                    style={{ border: "0px solid black" }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>                            
                </Container>
                <br /><br /><br /><br />
            </div>

            {/* Modal for displaying the image or PDF */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName={isPdf ? 'modal-fullscreen' : ''}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isPdf ? (
                        <iframe
                            src={`${process.env.REACT_APP_SERVER}/uploads/${modalContent}`}
                            title={modalTitle}
                            style={{ width: '100%', height: '80vh' }}
                            frameBorder="0"
                        />
                    ) : (
                        <img src={`${process.env.REACT_APP_SERVER}/uploads/${modalContent}`} alt={modalTitle} style={{ width: '100%', height: 'auto' }} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MasterProposalDetail;
