import React, { useEffect, useState, useCallback  } from 'react';
import axios from 'axios';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { Row, Col, Container, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaReadme } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/customTableStyles.css';
import { useNavigate } from 'react-router-dom';

const MasterProposal = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/data-proposal`)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    }, []);

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);

    const handleViewDetail = useCallback((user) => {
        navigate(`/master-proposal-detail/${user.status_proposal_id}/${user.username}`);
    }, [navigate]); 

    const columns = React.useMemo(
        () => [
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'ID', accessor: 'status_proposal_id', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },            
            { Header: 'NAMA_PERUSAHAAN', accessor: 'nama_perusahaan', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'KATEGORI', accessor: 'kategori_mitra', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'EMAIL', accessor: 'username', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'KOMISARIS UTAMA', accessor: 'komisaris_utama', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'DIREKTUR UTAMA', accessor: 'direktur_utama', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },            
            { Header: 'STATUS', accessor: 'status', Cell: ({ cell: { value }, row }) => (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{row.original.reason}</Tooltip>}
                >
                    <div style={{ textAlign: 'center' }}>{value}</div>
                </OverlayTrigger>
            ) },
            { Header: 'EVENT DATE', accessor: 'event_dt', Cell: ({ cell: { value }, row }) => (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>
                        <div className="text-start nowrap">
                            CR : {formatDate(row.original.created_at)}<br />
                            UP : {formatDate(row.original.updated_at)}
                        </div>
                    </Tooltip>}
                >
                    <div style={{ textAlign: 'center' }}>{value}</div>
                </OverlayTrigger>
            ) },
            {
                Header: 'ACTION', accessor: 'actions', Cell: ({ row }) => (
                    <div style={{ textAlign: 'center' }}>
                        <FaReadme onClick={() => handleViewDetail(row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                )
            },
        ],
        [handleViewDetail]
    );
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };      

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize },
        setPageSize,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,        
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
             <header className="mb-4">
                <h5 className="text-danger">Verifikasi Pengajuan Proposal</h5>
                Anda dapat melihat detail proposal dan melakukan response terhadap proposal yang telah di submit
            </header>
            <Container>                
                <Row>
                    <Col>
                        <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '12px' }}>
                            <thead className="custom-thead text-center">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                            >
                                                {column.render('Header')}
                                                <span className="sort-arrow"></span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} className="p-1">{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>            

        </div>
    );
};

export default MasterProposal;
