import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Fade from 'react-bootstrap/Fade'; // Import Fade component
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // Import CSSTransition and TransitionGroup
import './css/animations.css'; // Import custom animations

import FormInformasiPerusahaan from './FormInformasiPerusahaan';
import FormKeanggotaanPerusahaan from './FormKeanggotaanPerusahaan';
import FormFilePerusahaan from './FormFilePerusahaan';
import FormLaporanTigaTahun from './FormLaporanTigaTahun';
import FormDokumenPengajuan from './FormDokumenPengajuan';
import FormReview from './FormReview';

const FormPengajuan = ({uid, username, nama_perusahaan}) => {
    const [activeSection, setActiveSection] = useState('0');
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true); // Start animation when component is mounted
    }, []);

    const renderSection = () => {
        switch (activeSection) {
            case '1': return (<FormInformasiPerusahaan uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '2': return (<FormKeanggotaanPerusahaan uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '3': return (<FormFilePerusahaan uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '4': return (<FormLaporanTigaTahun uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '5': return (<FormDokumenPengajuan uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '0': return (<FormReview uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            default:
                return (<FormReview uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
        }
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF' }}>
            <Fade in={show} timeout={300}>
                <header className={`mb-4 ${show ? 'slide-up-enter-active' : 'slide-up-enter'}`}>
                    <h5>Formulir Pengajuan</h5>
                    Segera lengkapi data perusahaan anda agar dapat mengikuti proses selanjutnya
                </header>
            </Fade>

            <Container>
                <Row>
                    <Col xs={3} className="pt-3">
                        <TransitionGroup>
                            <CSSTransition
                                in={show}
                                timeout={300}
                                classNames="slide-up"
                                unmountOnExit
                            >
                                <ListGroup variant="flush">
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('1')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '1' ? 'red' : 'transparent', color: activeSection === '1' ? 'white' : 'black' }}
                                    >
                                        Informasi Perusahaan
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('2')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '2' ? 'red' : 'transparent', color: activeSection === '2' ? 'white' : 'black' }}
                                    >
                                        Keanggotaan Perusahaan
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('3')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '3' ? 'red' : 'transparent', color: activeSection === '3' ? 'white' : 'black' }}
                                    >
                                        File Perusahaan
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('4')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '4' ? 'red' : 'transparent', color: activeSection === '4' ? 'white' : 'black' }}
                                    >
                                        Laporan 3 Tahun Terakhir
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('5')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '5' ? 'red' : 'transparent', color: activeSection === '5' ? 'white' : 'black' }}
                                    >
                                        Dokumen Proposal
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('0')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '0' ? 'red' : 'transparent', color: activeSection === '0' ? 'white' : 'black' }}
                                    >
                                        Review
                                    </ListGroup.Item>
                                </ListGroup>
                            </CSSTransition>
                        </TransitionGroup>
                    </Col>
                    <Col xs={9} className="p-4">
                        <Fade in={show} timeout={300}>
                            <div className="slide-up-enter-active">
                                {renderSection()}
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FormPengajuan;
