import React from 'react';
import { FaBars, FaUser, FaKey, FaServicestack, FaSignOutAlt } from "react-icons/fa";

const Header = ({ toggleSidebar, username, nama_perusahaan }) => {
    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/logout`, {
                method: 'POST',
                credentials: 'include', // Include credentials to send cookies
            });

            const data = await response.json();
            if (data.loggedIn === false) {
                sessionStorage.clear();
                //navigate('/login');
                window.location.href = `${process.env.REACT_APP_FRONTEND}`;
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };
    return (
        <nav className="px-3 navbar navbar-expand navbar-light navbar-bg">
            <span className="sidebar-toggle js-sidebar-toggle" onClick={toggleSidebar}>
                <FaBars size={20} />
            </span>

            <h4 className="text-danger"> <b>Last Sprint eNd-GPP</b></h4>
            
            <div className="navbar-collapse collapse mx-3">
                <ul className="navbar-nav navbar-align">
                    <li className="nav-item dropdown">
                        <div
                            className="nav-link d-none d-sm-inline-block"
                            style={{ cursor: 'pointer' }}
                            data-bs-toggle="dropdown"
                        >
                            <FaUser size={15} /> {username}
                        </div>

                        <div className="dropdown-menu dropdown-menu-end">
                            <div className="dropdown-item">
                                <FaUser size={15}/> &nbsp; 
                                Profile
                            </div>
                            <div className="dropdown-item">
                                <FaKey size={15} /> &nbsp;
                                Change Password
                            </div>
                            <div className="dropdown-divider"></div>

                            <div className="dropdown-item">
                                <FaServicestack size={15}/> &nbsp;
                                Help Center
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="dropdown-item" onClick={handleLogout}>
                                <FaSignOutAlt size={15} /> &nbsp;
                                Log out
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Header;
